/* @jsx jsx */
import React, {useEffect, useState} from 'react'
import {jsx} from '@emotion/react'
import {graphql} from 'gatsby'
import Layout from '@containers/layout'
import GraphQLErrorList from '@components/graphql-error-list'
import Seo from '@components/seo'
import ContentWrapper from '@molecules/contentWrapper'
import Header from '@organisms/header'
import YoutubeSubscribeButton from '@molecules/youtubeSubscribe'
import {subscribeCss, sectionCss} from './styles'
import HeroImage from "@components/organisms/HeroImage"
import Sections from "@components/organisms/Sections"
import get from 'lodash.get'
import RandomVideos from '@organisms/RandomVideos'

const ProductPage = ({data, errors, path = ''}) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const {site, page} = data
  const {seo, global, header, footer} = site
  const {headerImage, sections, _rawSections, commentsEnabled, comments} = page

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Seo
        title={page.title}
        description={seo.description}
        keywords={seo.keywords}
        ogType='website'
        image={get(headerImage, 'asset.fluid.src')}
      />
      <ContentWrapper>
        <Header title={seo.title} {...header} showSearch={false} logoWidth={400} />

        {headerImage && (<HeroImage src={headerImage} />)}

        <Sections
          sections={sections}
          rawSections={_rawSections}
          commentsEnabled={commentsEnabled}
          comments={comments}
          css={sectionCss}
        />

        {path === '/apollo/' &&  (
          <RandomVideos
            title="Some random videos from Apollo"
            filter={videos => videos.filter(video => new RegExp(/apollo/i).test(video.title) )}
          />
        )}

        <p css={subscribeCss}>
          Don't forget to SUBSCRIBE! <YoutubeSubscribeButton channel="UCD8d3FGC907iS1qiMF0ccxA"/>
        </p>
      </ContentWrapper>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query PageQuery($slug: String!) {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
        title
        description
        keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
    page: sanityPage(slug: {eq: $slug}) {
      title
      _rawSections(resolveReferences: {maxDepth: 100})
      headerImage {
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          _id
        }
      }
      sections {
        ... on SanityPageColumns {
          _key
          _type
          columns {
            title
            width
            padding
            _rawSections
            sections {
              ... on SanityPageColumns {
                _key
                _type
                _rawColumns(resolveReferences: {maxDepth: 100})
              }
              ... on SanityPageContent {
                _key
                _type
                _rawBody(resolveReferences: {maxDepth: 100})
              }
              ... on SanityStarBarDemos {
                _key
                _type
                _rawBody(resolveReferences: {maxDepth: 100})
                selectLabel
              }
              ... on SanityGooglePlayButton {
                _key
                _type
                appId
              }
              ... on SanityPageComments {
                _key
                _type
                _rawDescription(resolveReferences: {maxDepth: 100})
              }
              ... on SanityYoutubeVideoSection {
                _key
                _type
                videoId
              }
              ... on SanityColorPickerSection {
                _key
                _type
                title
                style
              }
              ... on SanityStl {
                _key
                _type
                cameraPosition
                controls
                meshRotation
                scale
                title
                height
                width
                file {
                  asset {
                    url
                  }
                }
              }
              ... on SanityVfFieldStatus {
                _key
                _type
                title
                openMsg
                closedMsg
                noRnwyMsg
                showDatisWhenNoRnwy
              }
              ... on SanityPdf {
                _key
                _type
                title
                filename
                file {
                  asset {
                    url
                  }
                }
              }
              ... on SanityNoticeBar {
                _key
                _type
                title
                color
                showButton
                _rawBody
                button {
                  buttonUrl
                  color
                  text
                }
              }
              ... on SanityImageGallery {
                _key
                _type
                title
                _rawDescription
                galleryImages {
                  _key
                  _type
                  alt
                  description
                  title
                  image {
                    asset {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        src
                        sizes
                        srcSet
                      }
                      _key
                      _type
                      title
                      url
                    }
                  }
                }
              }
            }
          }
          _rawColumns(resolveReferences: {maxDepth: 100})
        }
        ... on SanityPageContent {
          _key
          _type
          _rawBody(resolveReferences: {maxDepth: 100})
        }
        ... on SanityStarBarDemos {
          _key
          _type
          _rawBody(resolveReferences: {maxDepth: 100})
          selectLabel
        }
        ... on SanityGooglePlayButton {
          _key
          _type
          appId
        }
        ... on SanityPageComments {
          _key
          _type
          title
          _rawDescription(resolveReferences: {maxDepth: 100})
        }
        ... on SanityYoutubeVideoSection {
          _key
          _type
          videoId
        }
        ... on SanityColorPickerSection {
          _key
          _type
          title
          style
        }
        ... on SanityStl {
          _key
          _type
          cameraPosition
          controls
          meshRotation
          scale
          title
          height
          width
          file {
            asset {
              url
            }
          }
        }
        ... on SanityVfFieldStatus {
          _key
          _type
          title
          openMsg
          closedMsg
          noRnwyMsg
          showDatisWhenNoRnwy
        }
        ... on SanityPdf {
          _key
          _type
          title
          filename
          file {
            asset {
              url
            }
          }
        }
        ... on SanityNoticeBar {
          _key
          _type
          title
          color
          showButton
          _rawBody
          button {
            buttonUrl
            color
            text
          }
        }
        ... on SanityImageGallery {
          _key
          _type
          title
          _rawDescription
          galleryImages {
            _key
            _type
            alt
            description
            title
            image {
              asset {
                fluid(maxWidth: 300, maxHeight: 300) {
                  src
                  sizes
                  srcSet
                }
                _key
                _type
                title
                url
              }
            }
          }
        }
      }
      commentsEnabled
      comments {
        comments {
          _key
          author
          body
          date
          status
        }
      }
    }
  }
`
