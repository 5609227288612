import {css} from '@emotion/react'
import {colors, fonts, unitConverter} from '@utils/base'
export const wrapperCss = css`
  padding: 0;
`

export const subscribeCss = css`
  color: ${colors.text};
  font-family: ${fonts.signo};
  line-height: 1.4;
  margin: ${unitConverter('40px 0 20px')};

  div {
    position: relative;
    top: 7px;
    left: 10px;
  }
`

